import { css, keyframes } from "styled-components";

import { media, px2rem, styled, theme } from "@styles";

const BulletBorderAnimation = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

export const DefaultHeroSliderWrapper = styled.section`
  width: 100%;
`;

export const Slider = styled.div`
  margin: 20px 0 40px;
  width: 100%;

  ${media.lg.up} {
    margin: 20px 0 60px;
  }
`;

export const SlidesWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  ${media.lg.up} {
    max-height: min-content;
  }
`;

export const BulletsWrapper = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: center;
  list-style: none;
  margin-top: 40px;
  width: 100%;

  ${media.lg.up} {
    grid-column-gap: 40px;
  }
`;

export const Bullet = styled.li<{
  interval: number;
  isActive?: boolean;
}>`
  ${media.lg.down} {
    background-color: ${({ isActive }) =>
      isActive ? theme.colors.black : theme.colors.dark};
    display: block;
    aspect-ratio: 1;
    width: 10px;
    border-radius: 50%;
  }

  ${media.lg.up} {
    cursor: pointer;
    font-size: ${px2rem(16)};
    font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
    color: ${({ isActive }) =>
      isActive ? theme.colors.black : theme.colors.dark};
    position: relative;

    ${({ isActive, interval }) =>
      isActive &&
      css`
        &:after {
          content: "";
          height: 3px;
          position: absolute;
          background-color: ${theme.colors.black};
          bottom: -12px;
          left: 0;
          animation: ${BulletBorderAnimation} linear ${interval}ms;
        }
      `}
  }
`;
