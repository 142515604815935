import { media, px2rem, styled } from "@styles";

export const Slide = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  transition: 450ms opacity ease-in-out;
  align-items: center;
  width: 100%;
  position: ${({ isActive }) => (isActive ? "relative" : "absolute")};
  height: 70vh;

  ${media.lg.up} {
    flex-direction: row;
    height: auto;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.lg.up} {
    text-align: initial;
    justify-content: center;
    flex: 0 0 50%;
    align-items: start;
    padding-right: 120px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 20px 0;
  align-items: center;

  ${media.lg.up} {
    row-gap: 16px;
    align-items: start;
  }
`;

export const Title = styled.p`
  font-size: ${px2rem(20)};

  ${media.lg.up} {
    font-size: ${px2rem(34)};
    line-height: ${px2rem(40)};
  }
`;

export const SubTitle = styled.p`
  font-size: ${px2rem(20)};
  font-weight: 700;

  ${media.lg.up} {
    font-size: ${px2rem(34)};
    line-height: ${px2rem(40)};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 6px;

  strong {
    font-weight: 600;
  }

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
  }

  a:visited {
    color: ${({ theme }) => theme.colors.dark};
  }

  * > {
    font-size: ${px2rem(18)};
    margin-bottom: 4px;

    ${media.lg.up} {
      font-size: ${px2rem(20)};
      line-height: ${px2rem(24)};
    }
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => theme.button.padding.main};
  text-decoration: none;
  width: max-content;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

export const Separator = styled.div`
  width: 100px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  aspect-ratio: 1/1;
  justify-content: right;
  align-items: center;
  max-width: 300px;

  button {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  ${media.lg.up} {
    max-width: 400px;
  }
`;
