import { paths } from "@paths";
import { asLink } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import Link from "next/link";
import { HeroSliderSliceDefaultItem } from "prismicio-types";
import React from "react";

import { useIsMdUp } from "@hooks/responsive";

import * as S from "./styles";

export type HeroSliderSlideProps = Omit<
  HeroSliderSliceDefaultItem,
  "nav_text" | "publish_end"
> & {
  index: number;
  isActive: boolean;
};

export const HeroSliderSlide = ({
  isActive,
  title,
  sub_title,
  description,
  button_link,
  button_text,
  index,
  image,
}: HeroSliderSlideProps) => {
  const isMdUp = useIsMdUp();
  const url = isMdUp ? image?.url : image?.mobile?.url ?? image?.url;

  const handleClick = () => {
    window.location.href = asLink(button_link) || paths.home;
  };

  return (
    <S.Slide
      aria-label="Hero Slider Slide"
      isActive={isActive}
      key={title}
      style={{
        opacity: isActive ? 1 : 0,
        zIndex: isActive ? 1 : -1,
      }}
    >
      <S.DescriptionWrapper>
        <S.Description>
          {title && <S.Title>{title}</S.Title>}
          {sub_title && <S.SubTitle>{sub_title}</S.SubTitle>}
          {description && (
            <S.InfoWrapper>
              <PrismicRichText field={description} />
            </S.InfoWrapper>
          )}
          <Link
            passHref
            href={asLink(button_link) || paths.home}
            prefetch={false}
          >
            <S.Link>{button_text}</S.Link>
          </Link>
        </S.Description>
      </S.DescriptionWrapper>
      <S.ImageWrapper aria-hidden>
        {url && (
          <button
            aria-label={title || "Slide image"}
            onClick={handleClick}
            tabIndex={index === 0 ? 0 : -1}
          >
            <Image
              alt={image?.alt || title || ""}
              layout="fill"
              loading={index === 0 ? "eager" : "lazy"}
              objectFit="contain"
              priority={index === 0}
              src={url}
              title={title || "Slide title"}
            />
          </button>
        )}
      </S.ImageWrapper>
    </S.Slide>
  );
};

HeroSliderSlide.displayName = "HeroSliderSlide";
