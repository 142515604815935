import React, { useCallback, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";

import { Container } from "@components/Container";
import { useIsLgUp } from "@hooks/responsive";
import { useInterval } from "@hooks/useInterval";
import { useRouter } from "@hooks/useRouter";

import { HeroSliderProps } from "../..";
import { HeroSliderSlide } from "./components";

import * as S from "./styles";

const HeroSlider = ({ slice }: HeroSliderProps): JSX.Element | null => {
  const interval = 5000;
  const withBullets = true;

  const { items: slides } = slice;
  const { locale } = useRouter();
  const isLgUp = useIsLgUp();
  const handlers = useSwipeable({
    onSwipedLeft: () => showNextSlide(),
    onSwipedRight: () => showPrevSlide(),
  });
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const showNextSlide = useCallback(() => {
    const nextSlideIndex =
      currentSlide < slides.length - 1 ? currentSlide + 1 : 0;
    setCurrentSlide(nextSlideIndex);
  }, [currentSlide, slides.length]);

  const showPrevSlide = () => {
    const prevSlideIndex =
      currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
    setCurrentSlide(prevSlideIndex);
  };

  useInterval(showNextSlide, interval, [currentSlide]);

  useEffect(() => setCurrentSlide(0), [locale]);

  return (
    <S.DefaultHeroSliderWrapper>
      <Container>
        <S.Slider>
          <S.SlidesWrapper {...handlers}>
            {slides?.length > 0 &&
              slides.map((slice, index) => (
                <HeroSliderSlide
                  {...slice}
                  index={index}
                  isActive={index === currentSlide}
                  key={`${slice?.title}${index}`}
                />
              ))}
          </S.SlidesWrapper>
          {withBullets && slides.length > 1 && (
            <S.BulletsWrapper>
              {slides.map(({ nav_text }, index) => (
                <S.Bullet
                  aria-label="Next slide"
                  interval={interval}
                  isActive={index === currentSlide}
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                >
                  {isLgUp && nav_text}
                </S.Bullet>
              ))}
            </S.BulletsWrapper>
          )}
        </S.Slider>
      </Container>
    </S.DefaultHeroSliderWrapper>
  );
};

export default HeroSlider;
