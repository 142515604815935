import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React from "react";

import { SliceVariants } from "@components/SliceVariants";

import { components } from "./variants";

/**
 * Props for `HeroSlider`.
 */
export type HeroSliderProps = SliceComponentProps<Content.HeroSliderSlice>;

/**
 * Component for "HeroSlider" Slices.
 */
const HeroSlider = ({ slice, ...config }: HeroSliderProps): JSX.Element => (
  <SliceVariants {...config} components={components} slice={slice} />
);

export default HeroSlider;
